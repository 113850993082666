import React from "react";
import "./EnvSignal.css";

const EnvSignal = () => {
     return (
          <>
               <div className="env-signal-container">TEST ENV</div>
          </>
     );
};

export default EnvSignal;
